<template>
  <div class="qj-list mb3">
    <ul class="pa-0">
      <li v-show="!doShowOnlyTotalBalance" class="qj-item-list" :class="{ 'border-b': hasManyOperations(), 'li-border': hasManyOrigins() }"
        v-for="operation in activeOperations" :key="operation.id">
        <div class="d-flex pb-0 lex-row justify-space-between">
          <div>
            <p class="operation-product" :class="{ 'font-weight-bold': !hasManyOperations() }">
              {{ operation.product.toLowerCase() }}
            </p>
            <br />
            <div class="d-flex flex-column align-start">
              <span class="mt-1" v-if="showDetails">
                {{ operation.details }}
              </span>
              <span v-if="operation.days_of_delay > 0">
                Vencido há mais de
                {{ operation.days_of_delay | value }}
                dias
              </span>
              <span v-else>
                Em dia
              </span>
            </div>
          </div>
          <p class="value" :class="{ 'font-weight-bold': !hasManyOperations() }">
            {{ operation.debit_value | money }}
          </p>
        </div>
        <div v-if="hasManyOrigins()">
          <originLine :inList="true" :operation="operation" :isCreditorSecuritizier="isCreditorSecuritizier"
            @openDialog="$emit('openDialog')" />
        </div>
      </li>
      <li v-if="hasManyOperations()" class="qj-item-list debit-balance">
        <p>Saldo devedor</p>
        <b class="value pt-0">{{ totalDebit() | money }}</b>
      </li>
    </ul>
    <v-col cols="12" class="pt-0" v-if="activeOperations?.[0]?.assignor && !hasManyOrigins()">
      <originLine :inList="false" :operation="activeOperations?.[0]" :isCreditorSecuritizier="isCreditorSecuritizier"
        @openDialog="$emit('openDialog')" />
    </v-col>
  </div>
</template>

<script>
import Deal from "@/modules/deal";
import originLine from "./originLine.vue";

export default {
  props: ["activeOperations", "showDetails", "isCreditorSecuritizier", "doShowOnlyTotalBalance"],
  components: {
    originLine,
  },
  methods: {
    hasManyOperations() {
      return (
        this.activeOperations.filter((operation) => operation.active === true)
          ?.length > 1
      );
    },
    hasManyOrigins() {
      let assignors = [];

      this.activeOperations.forEach(operation => {
        if (operation.assignor && !assignors.includes(operation.assignor)) {
          assignors.push(operation.assignor)
        }
      })

      if (assignors.length > 1) return true;
      return false;
    },
    totalDebit() {
      return Deal.calculateDebts(this.activeOperations);
    },
  },
};
</script>

<style scoped lang="scss">
.qj-list {
  list-style-type: none;
  background: #f6f6f6;
  border-radius: 7px;
  padding: 0 !important;
}

.qj-item-list {
  padding: 10px;
  line-height: initial;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.qj-item-list .value {
  font-size: 14px;
  margin: 0;
  padding-top: 10px;

  @media (max-width: 600px) {
    margin-left: 16px;
  }
}

.qj-item-list div {
  line-height: 0;
}

.qj-item-list div b {
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: #212121;

  @media (max-width: 375px) {
    font-size: 12px;
  }
}

.qj-item-list div span {
  font-style: normal;
  font-size: 10px;
  line-height: 12px;
  color: #212121;
}

.operation-product {
  text-transform: capitalize;
  line-height: normal;
  margin-bottom: 0;
  font-size: 14px;
}

.debit-balance p {
  margin-bottom: 0;
  font-size: 14px;
}

.debit-balance {
  flex-direction: row;
  font-weight: bold;
  border-top: 1px solid #666060;
}

.li-border {
  border-bottom: 1px solid #E5E5E5;

  &:nth-last-child(2) {
    border-bottom: none;
  }
}
</style>